console.log('Hello!');

// css
require('../css/app.scss');
require('normalize.css');
require('animate.css');
require('hamburgers/_sass/hamburgers/hamburgers.scss');
require('blueimp-gallery/css/blueimp-gallery.min.css');
require('plyr/dist/plyr.css');

// js
const simpleParallax = require('simple-parallax-js');
global.simpleParallax = simpleParallax;

const $ = require('jquery');
global.$ = global.jQuery = $;

// const blueimp = require('blueimp-gallery/js/blueimp-gallery.min');
// global.blueimp = blueimp;

// require('html5media/dist/api/1.1.8/html5media');
// console.log(html5media);

const gallery = require('blueimp-gallery');
global.gallery = gallery;

const plyr = require('plyr');
global.Plyr = plyr;

require('../js/app');

require('slick-carousel/slick/slick.scss');
import 'slick-carousel';
